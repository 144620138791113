import React from "react";
import DefaultLayout from "../layouts/default";
import { graphql } from "gatsby";
import { PageType } from "../types/Types";
import ContentBlocks from "../components/ContentBlocks";

export const PagesQuery = graphql`
fragment pagesPagesFragment on CMS_pages_pages_Entry {
  id
  title
  overviewDescription
  overviewButtonText
  overviewButtonLink
  uri
  url
  typeHandle
  backgroundColor
  backgroundColorCustom
  backgroundImage {
    id
    title
    url
    alt
  }
  overviewImage {
    id
    title
    url
    alt
    width
    height
  }
}

fragment contentBlocksFragment on CMS_pages_pages_Entry {
  contentBlocks {
    ... on CMS_contentBlocks_contentBlockMenuCardBlock_BlockType {
      ...contentBlockMenuCardBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockCatBlockRooms_BlockType {
      ...contentBlockCatBlockRoomsFragment
    }
    ... on CMS_contentBlocks_contentBlockCalendarBlock_BlockType {
      ...contentBlockCalendarBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockCategoriesTeaserBlock_BlockType {
      ...contentBlockCategoriesTeaserBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockTextBlock_BlockType {
      ...contentBlockTextBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockTitleBlock_BlockType {
      ...contentBlockTitleBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockImageBlock_BlockType {
      ...contentBlockImageBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockTwoColumnsBlock_BlockType {
      ...contentBlockTwoColumnsBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockNewsletterBlock_BlockType {
      ...contentBlockNewsletterBlockFragment
    }
  }
}

fragment contentBlockMenuCardBlockFragment on CMS_contentBlocks_contentBlockMenuCardBlock_BlockType {
  id
  title
  typeHandle
  menuCardDescription
  menuCardZusatz
  menuCardMenu {
    id
    ... on CMS_menus_menus_Entry {
      id
      title
      typeHandle
      menuEntry {
        ... on CMS_menuEntry_menuEntryTitleBlock_BlockType {
          id
          typeHandle
          menuEntryTitle
        }
        ... on CMS_menuEntry_menuEntryBlock_BlockType {
          id
          typeHandle
          anzahlPersonen
          menuEntryLabel
          menuEntryPrice
          menuEntryText
        }
        ... on CMS_menuEntry_menuEntryPageBreak_BlockType {
          id
          typeHandle
          menuEntryPageBreak
        }
      }
    }
  }
}

fragment contentBlockCatBlockRoomsFragment on CMS_contentBlocks_contentBlockCatBlockRooms_BlockType {
  id
  title
  typeHandle
  overviewPages {
    ... on CMS_pages_pages_Entry {
      ...pagesPagesFragment
    }
  }
}

fragment eventFragment on CMS_events_events_Entry {
  id
  title
  typeHandle
  eventDateDoorsOpen
  eventdateEventBegin
  priceBookingInAdvance
  priceBoxOffice
  ticketlink
  overviewDescription
  eventDescription
  uri
  overviewImage {
    id
    title
    alt
    url
    width
    height
  }
}

fragment contentBlockCalendarBlockFragment on CMS_contentBlocks_contentBlockCalendarBlock_BlockType {
  id
  title
  typeHandle
  calendarDescription
  calendarEvents {
    id
    ... on CMS_events_events_Entry {
      ...eventFragment
    }
  }
}

fragment contentBlockCategoriesTeaserBlockFragment on CMS_contentBlocks_contentBlockCategoriesTeaserBlock_BlockType {
  id
  title
  typeHandle
  teaserBlockCategories {
    ... on CMS_pages_pages_Entry {
      ...pagesPagesFragment
    }
  }
}

fragment contentBlockTextBlockFragment on CMS_contentBlocks_contentBlockTextBlock_BlockType {
  id
  title
  typeHandle
  textBlockText
}

fragment contentBlockTitleBlockFragment on CMS_contentBlocks_contentBlockTitleBlock_BlockType {
  id
  title
  typeHandle
  titleBlockTitle
}

fragment contentBlockImageBlockFragment on CMS_contentBlocks_contentBlockImageBlock_BlockType {
  id
  title
  typeHandle
  imageBlockImage {
    id
    title
    url
    alt
    height
    width
  }
}

fragment contentBlockTwoColumnsBlockFragment on CMS_contentBlocks_contentBlockTwoColumnsBlock_BlockType {
  id
  title
  typeHandle
  twoColumnsText
  twoColumnsImage {
    id
    title
    url
    alt
  }
  twoColumnsImgPos
}

fragment contentBlockNewsletterBlockFragment on CMS_contentBlocks_contentBlockNewsletterBlock_BlockType {
  id
  title
  typeHandle
  newsletterBlockText
}

query ($uri: String!, $siteId: CMS_QueryArgument) {
  cms {
    entry(uri: [$uri], siteId: [$siteId]) {
      id
      title
      typeHandle
      siteId
      language
      uri
      ancestors {
        id
        title
        typeHandle
        uri
      }
      ... on CMS_pages_pages_Entry {
        ...pagesPagesFragment
        ...contentBlocksFragment
      }
    }
  }
}

`;

const Page: React.FC<PageType> = ({
  data: {
    cms: {
      entry: { id, title, overviewDescription, overviewButtonText, overviewButtonLink, typeHandle, siteId, language, uri, backgroundColor, backgroundColorCustom, backgroundImage, contentBlocks },
    },
  },
}) => {

  return (
    <DefaultLayout
      id={id}
      title={title}
      description={overviewDescription}
      typeHandle={typeHandle}
      siteId={siteId}
      language={language}
      backgroundColor={backgroundColor}
      backgroundColorCustom={backgroundColorCustom}
      backgroundImage={backgroundImage}
    >
      <ContentBlocks contentBlocks={contentBlocks} language={language} overviewButtonText={overviewButtonText} overviewButtonLink={overviewButtonLink}/>
    </DefaultLayout>
  );
};

export default Page;
